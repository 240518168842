<template>
    <div class="demoniceye__all">
        <baydin-navbar title="နတ်မျက်စိ ဗေဒင်" />
        <div class="container mb-5 baydin-bg-img ">
            <div
                class="card mb-5"
                v-for="(question, index) in questions"
                :key="index"
                @click="goToAnswer(question.id)"
            >
                <div class="card-body">{{ question.name }}</div>
            </div>
        </div>
        <footer class="demonic__footer">
            <ul class="list-group list-group-horizontal">
                <li
                    class="list-group-item"
                    v-for="(question_type, index) in question_types"
                    :key="index"
                    @click="goToType(question_type.type)"
                >
                    {{ question_type.name }}
                </li>
            </ul>
        </footer>
    </div>
</template>

<script>
import BaydinNavbar from '@/components/reusable/BaydinNavbar.vue';

export default {
    components: {
        'baydin-navbar': BaydinNavbar,
    },

    data() {
        return {
            questions: [],
            question_types: [],
        };
    },

    mounted() {
        this.getQuestions();
        this.getQuestionTypes();
    },

    methods: {
        getQuestions() {
            var formdata = new FormData();
            formdata.append('type', this.$route.query.type);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow',
            };

            fetch(
                'https://ibaydin.pyaesoneshin.com/ibaydin/public/api/demoniceye/questions',
                requestOptions,
            )
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    this.questions = result.data.questions;
                })
                .catch(error => console.log('error', error));
        },

        getQuestionTypes() {
            var baydin_token = localStorage.getItem('baydin_token');

            var requestOptions = {
                method: 'GET',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${baydin_token}`,
                },
            };

            fetch(
                'https://ibaydin.pyaesoneshin.com/ibaydin/public/api/demoniceye/questiontypes',
                requestOptions,
            )
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    this.question_types = result.data.questions;
                })
                .catch(error => console.log('error', error));
        },

        goToType(type) {
            this.$router.replace({
                name: 'DemoniceyeType',
                query: {
                    type: type,
                },
            });
        },

        goToAnswer(id) {
            this.$router.push({
                name: 'DemoniceyeAnswer',
                params: {
                    id: id,
                },
            });
        },
    },

    watch: {
        $route() {
            this.getQuestions();
        },
    },
};
</script>

<style lang="scss">
.demonic__footer {
    position: fixed;
    bottom: 0;
}
</style>
<style lang="scss">
.baydin-bg-img {
    background-image: url('~@/assets/img/baydin/horoscope.jpg');
    height: 600vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
}

.pb-20 {
    padding-bottom: 20px;
}
</style>
