<template>
    <nav class="baydin__nav text-left pt-3 pb-3 shadow-sm d-flex ps-3">
        <font-awesome-icon
            :icon="['fas', 'chevron-left']"
            class="mt-1"
            @click="backTo"
        />
        <h3 class="fw-bold mb-0 ms-3">{{ title }}</h3>
    </nav>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            require: true,
        },
    },

    methods: {
        backTo() {
            this.$router.push({
                name: 'BaydinList',
            });
        },
    },
};
</script>
